export let DEFAULT_URL;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    DEFAULT_URL = "http://localhost:8000";
} else {
    DEFAULT_URL = "https://beta-api.mylivox.com";
}

export const GENDER_OPTIONS = [
    {value: "M", label: "Male"}, 
    {value: "F", label: "Female"}
];

export const LICENSES_STATUS_OPTIONS = [
    {value: 1, label: "Available"}, 
    {value: 2, label: "Active"},
    {value: 3, label: "Inactive"}, 
];
