import {DEFAULT_URL} from '../constants.js'

export const fetchData = (that) => {
  that.loading = true;
  get(that.endpoint).then((json) => {
    that.setState({
        data: json,
        loading: false
    })
  })
}

export const defaultState = () =>  ({
    loading: true,
    data: {
      results: []
    }
});

export const get = async (endpoint) => {
  
  return new Promise((resolve, reject) => {
    fetch(DEFAULT_URL + endpoint, {
        method: "GET",
        headers: {
          'Authorization': `JWT ${window.localStorage.getItem("token")}`
        }
      }).then(function(response) {
        if (response.status === 200){
          return response.json();
        } else{
          reject(response.status);
        }
      }).then(function(json) {
        resolve(json);
      }).catch(function(error) {
        reject(error)
      });
  });
}

export const post = async (endpoint, postData, contentType = "application/json") => {

  let data = postData;
  if (contentType === "application/json"){
    data = JSON.stringify(postData)
  }

  return new Promise((resolve, reject) => {
    fetch(DEFAULT_URL + endpoint, {
        method: "POST",
        headers: {
          'Content-Type': contentType,
          'Authorization': `JWT ${window.localStorage.getItem("token")}`
        },
        body: data
      }).then(function(response) {
        if (response.status === 200 || response.status === 201){
          return response.json();
        } else{
          reject(response.status);
        }
      }).then(function(json) {
        resolve(json);
      }).catch(function(error) {
        reject(error)
      });
  });

}
