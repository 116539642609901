import React from 'react';
import {InputField, renderForm, getFormParams} from '../utils'

export default class FormCustomer extends React.Component {

    constructor(){  
      super();
  
      this.title = "Add new customer"
      this.endpoint = "/portalapi/customers/new/create"
      this.state = {}
      this.state.fields = [
        new InputField("Branch Name", "name", true, {size: 1}),
        new InputField("Address", "address", true, {size: 1}),
        new InputField("City", "city", true, {size: 0.5}),
        new InputField("State", "state", false, {size: 0.25}),
        new InputField("Zipcode", "zipcode", true, {size: 0.25}),
        new InputField("Neighborhood", "neighborhood"),
        new InputField("Country", "country_code", false, {type: "select", options: [] }),
        new InputField("Phone", "phone"),
        new InputField("Contact Email", "email", true, {type: "email"}),
      ];
    }
  
    componentWillMount(){
      getFormParams(this);
    }
  
    onFormSuccess(data){
      window.location = `/customers/${data.id}`;
    }
  
    render (){ 
      return(renderForm(this));
    }
  
}
  