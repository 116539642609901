import React from 'react';
import {Attribute, renderSearchTable, licensesOptions} from '../utils'
import { Link } from "react-router-dom";
//TODO: Remove hardcoded strings
export default class SearchLicenses extends React.Component {

    constructor(){  
      super();
  
      this.title = "Licenses Search"
      this.endpoint =  "/portalapi/licenses/search"
      this.attributes = [
        new Attribute("Key", "key", 2, true),
        new Attribute("Install Key", "install_key", 1.5, true, (key) => <Link to={"/install-key/"+ key}> {key} </Link>),
        new Attribute("Customer", "customer_name", 1, "customer__name", (key, data) => <Link to={"/customers/"+ data.customer}> {key} </Link>),
        new Attribute("City", "customer_city", 1, "customer__city"),
        new Attribute("State", "customer_state", 0.5, "customer__state"),
        new Attribute("Country", "customer_country", 0.5, "customer__country"),
        new Attribute("Status", "status", 1, "status", licensesOptions),
      ];
  
    }
  
    render (){ 
      return(renderSearchTable(this));
    }
  
  }