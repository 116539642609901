import React, { Component } from 'react';
import './UserCard.scss';
import defaultImage from './userdefault.png';
import { Link } from "react-router-dom";
import { FaAngleDown } from 'react-icons/fa';
import { getUserData } from '../utils/currentUser';

class UserCard extends Component {

  constructor(){
    super();

    this.state = {
      name: '',
      dropdownOpen: false
    }
    
    this.toggleDropdown = () => {
      this.setState({
        dropdownOpen: !this.state.dropdownOpen
      })
    }
  }

  componentWillMount(){
    let data = getUserData();
    this.setState({
      name: data.full_name,
      customer: data.customer
    });
  }

  render() {
    return (
    <div className={"user-card-area" + (this.state.dropdownOpen ? " open" : "")}>
      <div className="user-card" onClick={this.toggleDropdown}>
        <img src={defaultImage} alt="Your avatar"/>
        <div>
          <span className="user-name"> {this.state.name} </span>
          <span className="user-customer"> {this.state.customer} </span>
        </div>
        <div className="dropdown-open">
          <span className="dropdown-icon">
            <FaAngleDown/>
          </span> 
        </div>
      </div>
      <div className="dropdown-options">
        <ul>
          <Link to="/profile">
            <li>
              My Profile
            </li>
          </Link>
          <li onClick={this.props.logOut.bind(this)}>               
            Log Out
          </li>
        </ul>
      </div>
 
    </div>
    );
  }
}

export default UserCard;