import React from 'react';
import {Attribute, renderSearchTable, dateToLocale} from '../utils'

export default class AccountLicenses extends React.Component {

    constructor(){  
      super();
  
      this.title = "My Licenses"
      this.endpoint = "/portalapi/mylicenses"; 
      this.attributes = [
        new Attribute("Install Key", "install_key", 2),
        new Attribute("Expire", "expire", 1, null, dateToLocale),
        new Attribute("Support", "support", 1, null, dateToLocale),
        new Attribute("Device Name", "device_name", 1),
        new Attribute("MAC Address", "device_macaddress", 1),
        new Attribute("Device Version", "device_version", 1),
        new Attribute("Status", "status_name", 1),
      ];
  
    }
  
    render (){ 
      return(renderSearchTable(this));
    }
  }