import React from 'react';
import {fetchData, defaultState} from '../../utils/fetch'
import {ROUTES} from '../../routes'
import {Link} from 'react-router-dom'
import { FaEdit } from 'react-icons/fa';
import {InputField} from '../utils'

export default class Profile extends React.Component {

    constructor(){  
      super();
  
      this.endpoint = "/portalapi/myprofile"; 
      this.title = "My Users"
      this.costumerFields =[
        new InputField("Name", "name", false, {size:0.5 }),
        new InputField("Phone", "phone_number", false, {size:0.5 }),
        new InputField("Address", "address", false, {size:0.5 }),
        new InputField("Neighborhood", "neighborhood", false, {size:0.5 }),
        new InputField("State", "state", false, {size:0.5 }),
        new InputField("Zipcode", "zipcode", false, {size:0.5 }),
        new InputField("Country", "country_name", false, {size:0.5 }),
      ];
      
      this.authUserFields =[
        new InputField("Name", "full_name", false, {size:0.5 }),
        new InputField("Customer", "customer", false, {size:0.5 }),
        new InputField("Gender", "gender", false, {size:0.5 }),
        new InputField("Language", "language", false, {size:0.5 }),
      ];

      this.holderFields =[
        new InputField("Address", "address", false, {size:0.5 }),
        new InputField("City", "city", false, {size:0.5 }),
        new InputField("State", "state", false, {size:0.5 }),
        new InputField("Zipcode", "zipcode", false, {size:0.5 }),
        new InputField("Phone", "phone", false, {size:0.5 }),
        new InputField("Country", "country", false, {size:0.5 }),
      ];

      this.state = defaultState();
      this.actions = [ROUTES.PROFILE_EDIT, /*ROUTES.CHANGE_PASSWORD*/];
    }

    mapfield(field, data) {
      return (
        <div className="data-field" key={field.key} style={{width: field.size * 100 + "%"}}>
          <span className="label"> {field.name}: </span>
          <span className="value"> {data[field.key] || "-"} </span>
        </div>
      )
    }

    componentDidMount() {
      fetchData(this);
    }
  
    render (){
  
      if (this.state.loading){
        return(<span> Loading </span>);
      }

      let buttons = this.actions.map((action) => (
          <Link to={action.path} key={action.path}>
            <button className="form-button">
              <FaEdit/>
              {action.name}
            </button>
          </Link>
        )
      )
      let customerData = this.state.data.customer;
      let holderData = this.state.data.holder;
      let authUserData = this.state.data.authuser;
      
      let customer = this.costumerFields.map((field) => this.mapfield(field, customerData));
      let holder = this.holderFields.map((field) => this.mapfield(field, holderData));
      let authUser = this.authUserFields.map((field) => this.mapfield(field, authUserData));

      return(
        <div className="profile">
          <div className="data-header">
            <h1>Profile</h1>
            <div className="buttons">
              {buttons}
            </div>
          </div>

          <div className="panel">
            <h3 className="panel-title">
              Customer
            </h3>

            <div className="panel-body">
              {customer}
            </div>
          </div>
  
          <div className="panel panel-primary">
            <h3 className="panel-title">
                Holder Info
            </h3>
            <div className="panel-body">
              {authUser}
              {holder}
            </div>
          </div>
        </div>

      );
    }
  
  }
  