import React from 'react';
import {InputField, renderForm, getFormParams} from '../utils'

export default class FormLicense extends React.Component {

    constructor(){  
      super();
  
      this.title = "New License";
      let customerId = localStorage.getItem("currentCustomer");
      this.endpoint = `/portalapi/licenses/${customerId}/create`;
      let state = {};
      state.fields = [
        new InputField("Type", "type", true, {type: "select", options: []  }),
        new InputField("Distribution", "distribution", true, {type: "select", options: []   }),
        new InputField("Language", "language", true, {type: "select", options: []  }),
        new InputField("Reseller", "reseller", true, {type: "select", options: []  }),
        new InputField("Text", "text", false),
        new InputField("Number of Devices", "number_of_devices", true, {type: "number"}),
        new InputField("Lifetime License", "lifetime_license", false, {type: "checkbox", size: 1}),
        new InputField("Expire", "expire_date", true, {type: "date"}),
        new InputField("Support", "support_date", true, {type: "date"}),
      ];

      this.state = state;
    }

    componentWillMount(){
      getFormParams(this);
    }
  
    onFormSuccess(){
      window.history.back();
    }
  
    render (){ 
      return(renderForm(this));
    }
  
}
  