import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json"
import br from "./pt-BR.json"
import ar from "./ar.json"

i18n
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: en
      },
      "pt_BR": {
        translations: br
      },
      "ar_SA": {
        translations: ar
      }
    },
    fallbackLng: "en",
    debug: process.env.NODE_ENV === 'development',

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
