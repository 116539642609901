import React from 'react';
import './utils/loader.scss';
import NavBar from './navbar/NavBar.js';
import View from './view/View.js';
import Auth from './auth/Auth.js';
import {getUserLogin, loginSuccess, logOut} from './utils/currentUser';
import {get} from './utils/fetch';

export default class Portal extends React.Component{
  constructor() {
    super();
    
    let state = {
      loggedIn: getUserLogin()
    }

    this.state = state;
    this.loginSuccess = (token) => {
      loginSuccess(token);
      get("/portalapi/myuser/").then((json) => {
        window.localStorage.setItem('currentUser', JSON.stringify(json));
        this.setState({loggedIn: true});
      });
    };

    this.logOut = () => {
      logOut();
      this.setState({loggedIn: false});
    };

  }

  render(){

    let request;
    if (this.state.loggedIn){
        request = (
            <div className="Center">
                <NavBar logOut={this.logOut.bind(this)}/>
                <View/>
            </div>
        )
    } else{
        request = (<Auth user={this.state.user} loginSuccess={this.loginSuccess}/>);
    }

    return request;
  }
}
