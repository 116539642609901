import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {post} from '../utils/fetch'

function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

export default class CreateForm extends React.Component{

    cancelForm(){
        window.history.back();
    }

    render(){
        let props = this.props;
        let initialValues = {};
        this.formFields = props.fields.map((attr) => {
            initialValues[attr.key] = attr.defaultValue || "";
            // Multiple options
            let field;
            if (attr.type === "select"){
                let options = attr.options.map((option) => {
                    return (
                        <option key={option.value} value={option.value} label={option.label}></option>
                    )
                });

                field = (
                    <Field component="select" name={attr.key}>
                            <option value="" label={"Select a " + attr.name}></option>
                            {options}
                    </Field>
                )
            } else {
                field = (
                    <Field type={attr.type} name={attr.key} required={attr.required} disabled={attr.disabled}/>
                )
            }

            return (
                <div key={attr.key} className="field" style={{width: attr.size * 100 + "%"}}>
                    <label> {attr.name} </label>
                    {field}
                    <ErrorMessage name={attr.key} component="span" className="error" />
                </div>
            )
        });


        let that = this;
        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                
                let dataEncoded = new URLSearchParams(getFormData(values)).toString();

                post(this.props.endpoint, dataEncoded, "application/x-www-form-urlencoded").then((res) => {
                    setSubmitting(false);
                    if (res.success === false){
                        alert(res.message)
                    } else {
                        that.props.onFormSuccess(res);
                    }
                }); 
                    
            }}>

                {({ isSubmitting }) => (
                    <Form className="form form-creation">
                        <legend> {this.props.title} </legend>
                        {this.formFields}
                        <div className="form-buttons">
                            <button type="button"  disabled={isSubmitting} onClick={this.cancelForm}>
                                Cancel
                            </button>
                            <button type="submit" disabled={isSubmitting}>
                                Create
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>  
        )
    }
}
