import React from 'react';
import {Attribute, renderSearchTable} from '../utils'
import {ROUTES} from '../../routes';

export default class AccountAuthUsers extends React.Component {

    constructor(){  
      super();
  
      this.title = "My Auth Users"
      this.endpoint = "/portalapi/myauthusers";
      this.attributes = [
        new Attribute("Name", "full_name", 1),
        new Attribute("Email", "email", 1),
        new Attribute("Customer", "customer", 1),
      ];

      this.links = [ROUTES.ACCOUNT_AUTH_USER_CREATE];
    }

    render (){ 
      return(renderSearchTable(this));
    }
  }
  
  