import React from 'react';
import Table from './table/Table.js';
import Pagination from './Pagination';
import { Link } from "react-router-dom";
import Search from './search/Search';
import AttributesSearch from './AttributesSearch';
import Export from './Export';
import {fetchData, defaultState} from '../utils/fetch'
import { FaPlusCircle } from 'react-icons/fa';
import './dataview.scss';
import { withTranslation } from 'react-i18next';

let jsonToQueryString = (json) => {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
}

class DataView extends React.Component{

    constructor(props){
        super(props);
        let state = defaultState();
        state.formOpen = false;
        state.query = {}
        state.attributes = this.props.attributes.filter((attr) => attr.searchKey).map((attr) => {
            if (attr.searchKey === true){
                return {[attr.key]: ""}
            } else{
                return {[attr.searchKey]: ""}
            }
        })

        this.state = state;
        this.endpoint = this.props.endpoint;
    }

    componentDidMount() {
        document.title = this.props.t(this.props.title) + " - Livox";
        fetchData(this);
    }
    
    onParamsChange(params) {
        this.endpoint = this.props.endpoint + params;
        fetchData(this);
    }
        
    updateQuery(key, value){
        let newQuery = {
            ...this.state.query,
            [key]: value
        }
        
        this.setState({query: newQuery}, () => this.onParamsChange(jsonToQueryString(this.state.query)));
    }

    onFormSuccess(){
        fetchData(this);
    }

    render(){

        let buttons = this.props.links.map((link) => {
            return (
                <Link key={link.path} to={link.path}>
                    <button className="form-button">                     
                        <FaPlusCircle/>{link.name}
                    </button>
                </Link>
            )
        })

        let pagination = null;
        if (!this.state.loading){
            pagination = <Pagination data={this.state.data} updateQuery={this.updateQuery.bind(this)}/>
        }

        return (           
            <div>
                <div className="data-header">
                    <h1>{ this.props.t(this.props.title) }</h1>
                    <div className="buttons">
                        {buttons}
                    </div>
                </div>
                <div className="table-control">
                    <Search updateQuery={this.updateQuery.bind(this)}/>
                    <Export updateQuery={this.updateQuery.bind(this)} loading={this.state.loading} data={this.state.data}/>
                </div>
                <AttributesSearch attributes={this.props.attributes} updateQuery={this.updateQuery.bind(this)}/>
                <Table attributes={this.props.attributes} results={this.state.data.results} loading={this.state.loading} updateQuery={this.updateQuery.bind(this)}/>
                {pagination}
                { this.state.loading ?
                    <div className="loader"></div>
                    :
                    null
                }
            </div>
    );
  }
}

export default withTranslation()(DataView);
