import React from 'react';
import {Attribute, renderSearchTable} from '../utils';
import {ROUTES} from '../../routes';
import { Link } from "react-router-dom";

export default class AccountBranches extends React.Component {

    constructor(){  
      super();
  
      this.title = "My Branches"
      this.endpoint = "/portalapi/mybranches";
      this.attributes = [
        new Attribute("Name", "name", 2, null, (key, data) => <Link to={"/customers/"+ data.id}> {key} </Link>),
        new Attribute("Num. Licenses", "status", 1),
        new Attribute("Available", "device_version", 1),
        new Attribute("Activated", "num_active", 1),
        new Attribute("Inactivated", "num_inactive", 1),
        new Attribute("City", "city", 1),
        new Attribute("State", "state", 1),
        new Attribute("Country", "country_code", 1),
        new Attribute("Phone", "phone", 1),
      ];

      this.links = [ROUTES.BRANCH_CREATE];
    }

    render (){ 
      return(renderSearchTable(this));
    }
  
  }