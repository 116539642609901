import React from 'react';
import {InputField, renderForm} from '../utils'

let baseUrl = window.location.origin + "/register/"

export default class RegistrationLink extends React.Component {

  constructor(props){  
    super(props);

    this.title = "Create License Registration Link"
    this.endpoint = `/portalapi/licenses/createregistration/${props.match.params.installKey}`
    this.state = {
      generated: false,
      registerLink: "",
      token: null
    }
    this.state.fields = [
      new InputField("Email", "email", true, {type: "email", size:1}),
    ];    
  }

  onFormSuccess(e){
    this.setState({generated: true, registerLink: baseUrl + e.token, email: e.email, installKey: e.install_key})
  }

  copyToClipboard(){
    var copyText = document.getElementById("registration-link");
    copyText.select();
    document.execCommand("copy");
  
  }

  done(){
    window.history.back();
  }

  render (){ 

    let render = renderForm(this);

    if (this.state.generated){
      render = ( 
      <div className="form form-creation">
        <legend> Registration Link Created</legend>
        <div className="panel">
          <div className="panel-body">
            <div className="data-field block">
              <span className="label"> Email: </span>
              <span className="value"> {this.state.email} </span>
            </div>
            <div className="data-field block">
              <span className="label"> Install Key: </span>
              <span className="value"> {this.state.installKey} </span>
            </div>
            <div className="data-field block">
              <span className="label"> Registration Link: </span>
              <input defaultValue={this.state.registerLink} readOnly={true} id="registration-link"/>
            </div>
          </div>
        </div>

        <div className="form-buttons">
          <button onClick={this.copyToClipboard}>Copy to Clipboard</button>
          <button onClick={this.done}>Done</button>
        </div>
      </div>)
    }

    return(
      render
    );
  }
  
}
  