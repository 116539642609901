import React from 'react';
import {Attribute, renderSearchTable, dateToLocale} from '../utils'
import {DEFAULT_URL} from '../../constants.js'
import { FaTrash } from 'react-icons/fa';

export default class AccountLivoxUsers extends React.Component {

    constructor(){  
      super();
      this.handleDeleteUser = this.handleDeleteUser.bind(this);
      this.title = "My Users"
      this.endpoint = "/portalapi/myusers"; 
      this.attributes = [
        new Attribute("User", "first_name", 2),
        new Attribute("Gender", "gender", 1),
        new Attribute("Age", "birthdate"),
        new Attribute("Contact", "support", 2),
        new Attribute("Evaluations", "num_evaluations", 1),
        new Attribute("Diagnosis", "disability", 1),
        new Attribute("Device*", "device_version", 1),
        new Attribute("Last Evalution", "last_evaluation", 0.5, null, dateToLocale),
        new Attribute("Delete User", "id", 1, null, (value) => <button onClick={this.handleDeleteUser} data-id={value}> <FaTrash/> </button>),
      ];
  
    }

    handleDeleteUser(event) {
      let that = this;
      fetch(DEFAULT_URL + '/portalapi/ajax/livoxUsers/'+ event.target.getAttribute('data-id') +'/delete/', {
        method: 'POST',
        headers: {
          'Content-Type': "application/json",
          'Authorization': `JWT ${window.localStorage.getItem("token")}`
        },
        body: JSON.stringify({id : event.target.getAttribute('data-id')})
      }).then(() => {
        that.forceUpdate();
      })
    }
  
    render (){ 
      return(renderSearchTable(this));
    }

  }
  