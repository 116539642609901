import React from 'react';
import {FaCloudDownloadAlt} from 'react-icons/fa'

function csvExport(){
    let csv ='data:text/csv;charset=utf-8,\uFEFF'
    const nodesSnapshot = document.evaluate(".//tr", document.getElementById("data-table"), null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
    for (let i = 0; i < nodesSnapshot.snapshotLength; i++ ) {
        const line = Array.from(nodesSnapshot.snapshotItem(i).childNodes);
        csv += line.map((nodes) => nodes.innerText).join("; ") + "\n";
    }
    let encodedUri = encodeURI(csv);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "export.csv");
    document.body.appendChild(link);
    link.click();

}

export default class Export extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            exporting: false
        }    
    }


    exportToCSV(){

        this.setState({exporting: true});
        //this.props.updateQuery("limit", this.props.data.count);
        csvExport();

        
    }
    /*
    static getDerivedStateFromProps(nextProps, prevState){
        let data = nextProps.data;
        if (data.count === data.results.length){
            csvExport();
            nextProps.updateQuery("limit", 20);
            return {exporting: false};

        }
        return null;
     }
    */

    render(){

        return (           
            <div>
                <button className="secundary" onClick={this.exportToCSV.bind(this)}> <FaCloudDownloadAlt/> Download</button>
            </div>
    );
  }
}

