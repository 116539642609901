import React from 'react';
import {Attribute, renderSearchTable, dateToLocale, createRegistrationLink, licensesOptions} from '../utils'
import {ROUTES} from '../../routes';

export default class CustomerLicenses extends React.Component {

    constructor(props){  
      super(props);
      this.title = "Customer Licenses"
      //this.endpoint = "/portalapi/licenses/search"; 
      this.endpoint = `/portalapi/customers/${props.match.params.id}/licenses`
      this.attributes = [
        new Attribute("Key", "key", 2.5),
        new Attribute("Install Key", "install_key", 1.7),
        new Attribute("Expire", "expire", 1, null, dateToLocale),
        new Attribute("Support", "support", 1, null, dateToLocale),
        new Attribute("Holder", "holder_name", 1, null, createRegistrationLink),
        new Attribute("Status", "status", 1, null, licensesOptions),
      ];

      this.links = [ROUTES.LICENSE_CREATE, /*ROUTES.CREATE_AUTH_USER*/];
    }

    componentWillMount(){
      localStorage.setItem("currentCustomer", this.props.match.params.id);
    }
 
    render (){ 
      return(renderSearchTable(this));
    }

  }
  