import React from 'react';
import {InputField, renderForm, getFormParams} from '../utils'
import {GENDER_OPTIONS} from '../../constants'

export default class FormAuthUser extends React.Component {

    constructor(){  
      super();
  
      this.title = "New Auth User"
      let customerId = localStorage.getItem("currentCustomer");
      this.endpoint = `/portalapi/customers/${customerId}/users/add`
      this.state = {}
      this.state.fields = [
        new InputField("First name", "first_name", true),
        new InputField("Last name", "last_name", true),
        new InputField("Contact Email", "email", true, {type: "email", size:0.33 }),
        new InputField("Gender", "gender", true, {type: "select", options: GENDER_OPTIONS, size:0.33}),
        new InputField("Language", "language", true, {type: "select", options: [], size:0.33  }),
        new InputField("Group", "group", true, {type: "select", options: [], size:0.5   }), 
        new InputField("Reseller", "reseller", false, {type: "select", options: [], size:0.5}),
        new InputField("Password", "password", true, {type: "password"}),
        new InputField("Confirm Password", "confirm_password", true, {type: "password"}),
      ];
    }

    componentWillMount(){
      getFormParams(this);
    }

    onFormSuccess(){
      window.history.back();
    }
  
    render (){ 
      return(renderForm(this));
    }
  
}
  