import React from 'react';
import {Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';
import './App.scss';
import i18n from './i18n/config';
import {userLanguage} from './utils/currentUser';
import ReactGA from 'react-ga';
import FormRegister from './view/views/FormRegister';
import {ROUTES} from './routes'
import Portal from './Portal';

ReactGA.initialize('UA-66887147-5');

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});
i18n.changeLanguage(userLanguage());

class App extends React.Component{

  render(){
    let useRTL = userLanguage() === "ar_SA";
    return (
      <Router history={history}>
        <div className={useRTL ? "rtl" : null}>
          <Switch>
            <Route
              exact
              path={ROUTES.REGISTER.path}
              component={FormRegister} />
            <Route component={Portal} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
