import React from 'react';
import './auth.scss';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {DEFAULT_URL} from '../constants.js'
  
class Auth extends React.Component{

    render() {
        let props = this.props;
        return (
            <div className="login-modal">
            <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={(values, { setSubmitting }) => {
                
                fetch(DEFAULT_URL + "/token/get", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(values),
                }).then(function(response) {
                    if (response.status === 400){ // Invalid login
                        return Promise.reject("Bad Login")
                    } else if (response.ok) {
                        return response.json();
                    } else{
                        console.log({response})
                    }
                }).then(function(json) {
                    props.loginSuccess(json.token);
                });

                setSubmitting(false);
            }}
            >
            {({ isSubmitting }) => (
                <Form className="master-form form">
                <legend>Login</legend>
                <label> Email </label>
                <Field type="email" name="username" placeholder="youremail@livox.com" required/>
                <ErrorMessage name="username" component="span" className="error" />
                <label> Password </label>
                <Field type="password" name="password" placeholder="••••••" required/>
                <ErrorMessage name="password" component="span" className="error"/>
                <button type="submit" disabled={isSubmitting}>
                    Log In
                </button>
                </Form>
            )}
            </Formik>

            </div>
        );
    }
}
  
  export default Auth;
  