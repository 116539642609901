import React from 'react';
import {InputField, renderForm, getFormParams} from '../utils'

export default class FormCustomer extends React.Component {

  constructor(){  
    super();

    this.title = "New Branch"
    this.endpoint = "/portalapi/create/branch"
    this.state = {}
    this.state.fields = [
      new InputField("Name", "name", true),
      new InputField("Address", "address", true),
      new InputField("City", "city", true),
      new InputField("State", "state", true),
      new InputField("Zipcode", "zipcode", true),
      new InputField("Country", "country_code", true, {type: "select", options: []  }),
      new InputField("Neighborhood", "neighborhood"),
      new InputField("Phone", "phone", true),
      new InputField("Contact Email", "email", true, {type: "email"}),
    ];    
  }

  componentWillMount(){
    getFormParams(this);
  }

  //TODO change to branch id
  onFormSuccess(){
    window.history.back();
  }

  render (){ 
    return(renderForm(this));
  }
  
}
  