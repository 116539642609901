import React from 'react';
import { FaSearch } from 'react-icons/fa';
import "./search.scss"

export default class Search extends React.Component{

    handleChange(event) {
        this.setState({search: event.target.value});
        this.props.updateQuery("search", event.target.value);
    }

    render(){

        return (
            <div className="search-bar">
                <FaSearch/>
                <input onChange={this.handleChange.bind(this)} type="search" placeholder="Search"/>
            </div>     
        );
  }
}
