const storageGet = (key) => {
    return window.localStorage.getItem(key);
}

const storageSet = (key, value) => {
    window.localStorage.setItem(key, value);
}

export const getUserLogin = () => {
    if (storageGet('loggedIn') === null){
        storageSet('loggedIn', 'false');
    }
    return storageGet('loggedIn') === "true";
}

export const loginSuccess = (token) => {
    storageSet('loggedIn', 'true');
    storageSet('token', token);
}

export const logOut = () => {
    storageSet('loggedIn', 'false');
    storageSet('token', '');
    storageSet('currentUser', null);
}

export const getUserData = () => {
    return JSON.parse(storageGet('currentUser')) || [];
}

export const hasPermission = (permission) => {
    const userPermission = getUserData().permissions || [];
    return !permission || userPermission.find((perm) => perm === permission);
}

export const userLanguage = () => {
    return getUserData().language || "en";
}