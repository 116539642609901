import React from 'react';
import DataView from '../data-view/DataView';
import CreateForm from '../forms/CreateForm';
import { get, post } from '../utils/fetch';
import { Link } from "react-router-dom";
import {LICENSES_STATUS_OPTIONS} from '../constants'
import i18n from "../i18n/config"
import {userLanguage} from '../utils/currentUser';

i18n.changeLanguage(userLanguage());

export class Attribute {
    constructor(name, key, size, searchKey, render){
        this.name = i18n.t(name);
        this.key = key;
        this.size = size;

        if (searchKey === true){
            this.searchKey = key;
        } else{
            this.searchKey = searchKey;
        }
            this.render = render;
    }
}

export class InputField {
    constructor(name, key, required, params){
      this.name = i18n.t(name);
      this.key = key;
      this.required = required;
      this.type = "text";
      if (params){
          this.type = params.type;
          this.options = params.options;
          this.size = params.size;
          this.defaultValue = params.defaultValue;
          this.disabled = !!params.disabled;
      }
  }
}

export const getFormParams = (that) =>{
  get(that.endpoint).then((params) => {

    let newField = that.state.fields;

    let options = params.options;
    if (options) {

      // Apply options
      newField.filter(field => options[field.key]).forEach(field => {
          let formOptions = Object.entries(options[field.key]).map(([key, value]) => (
            {value: key, label: value}
          ));
          
          field.options = formOptions;
      })

    }

    let defaultValues = params["default_values"];
    if (defaultValues){

      // Apply options
      newField.filter(field => defaultValues[field.key]).forEach(field => {
        field.defaultValue = defaultValues[field.key];
      })
      console.log(newField)
    }

    // Set the new fields
    that.setState({
      fields: newField
    })

  }).catch((error) => {
        console.error(error);
  });

}

export const dateToLocale = (date) => date && new Date(date).toLocaleDateString("pt-BR");
export const renderSearchTable = (that) => <DataView endpoint={that.endpoint} attributes={that.attributes} title={that.title} links={that.links || []} />
export const renderForm = (that) => <CreateForm fields={that.state.fields} title={that.title} endpoint={that.endpoint} onFormSuccess={that.onFormSuccess.bind(that)} />


// Licenses
export const createRegistrationLink = (name, data) => <span> {name !== null ? name : <Link to={`/install-key/${data.install_key}/link`}> Registration Link </Link> }</span>

const changeLicenseStatus = (e) =>{

  let data = {
    id: e.target.getAttribute('data-id'),
    status: e.target.value,
    type: "status"
  }
  
  post("/portalapi/licenses/change-status", data).then((res) => {
    //console.log("License" + res + "changed!");
  }); 


}

export const licensesOptions = (initialStatus, {id}) => {
  let options = LICENSES_STATUS_OPTIONS.map((status) => <option key={status.value} value={status.value}>{status.label}</option>);

  return(
    <select name="status" defaultValue={initialStatus} onChange={changeLicenseStatus} data-id={id}>
      {options}
    </select>
  );
}
