import React from 'react';
import {Attribute, renderSearchTable, dateToLocale, createRegistrationLink, licensesOptions} from '../utils'

export default class InstallKey extends React.Component {

  constructor(props){  
      super(props);
  
      this.title = "Install Key"
      this.endpoint = `/portalapi/install-key/${props.match.params.installKey}`; 
      this.attributes = [
          new Attribute("Key", "key", 2.5),
          new Attribute("Install Key", "install_key", 1.5),
          new Attribute("Expire", "expire", 1, null, dateToLocale),
          new Attribute("Support", "support", 1, null, dateToLocale),
          new Attribute("Holder", "holder_name", 2, null, createRegistrationLink),
          new Attribute("Status", "status", 1, null, licensesOptions),
    ];
  }

  render (){ 
    return(renderSearchTable(this));
  }
}

