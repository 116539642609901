import React from 'react';
import { Route, Switch } from "react-router-dom";
import './view.scss';
import {ROUTES} from '../routes'

import Profile from './views/Profile'
import SearchLivoxUsers from './views/SearchLivoxUsers'
import SearchCustomers from './views/SearchCustomers'
import SearchLicenses from './views/SearchLicenses'
import AccountDevices from './views/AccountDevices';
import AccountLicenses from './views/AccountLicenses';
import AccountLivoxUsers from './views/AccountLivoxUsers';
import PortalAdmin from './views/PortalAdmin';
import AccountBranches from './views/AccountBranches';
import AccountAuthUsers from './views/AccountAuthUsers';
import FormCustomer from './views/FormCustomer'
import FormBranch from './views/FormBranch'
import FormAuthUser from './views/FormAuthUser';
import InstallKey from './views/InstallKey';
import CustomerLicenses from './views/CustomerLicenses';
import FormLicense from './views/FormLicense';
import NotFound from './views/NotFound';
import RegistrationLink from './views/RegistrationLink';
import FormUpdateProfile from './views/FormUpdateProfile';
import FormRegister from './views/FormRegister';

class View extends React.Component{
  render (){
    return (
        <div className="View">
        <Switch>
          <Route
            exact
            path={ROUTES.DASHBOARD.path}
            component={AccountAuthUsers} />
          <Route
            exact
            path={ROUTES.ACCOUNT_DEVICES.path}
            component={AccountDevices} />
          <Route
            exact
            path={ROUTES.ACCOUNT_LICENSES.path}
            component={AccountLicenses} />

          <Route
            exact
            path={ROUTES.LICENSES_SEARCH.path}
            component={SearchLicenses} />

          <Route
            exact
            path={ROUTES.INSTALL_KEY.path}
            component={InstallKey} />

          <Route
            exact
            path={ROUTES.ACCOUNT_LIVOX_USERS.path}
            component={AccountLivoxUsers} />

          <Route
            exact
            path={ROUTES.CUSTOMER_CREATE.path}
            component={FormCustomer} />

          <Route
            exact
            path={ROUTES.LIVOX_USERS_SEARCH.path}
            component={SearchLivoxUsers} />

          <Route
            exact
            path={ROUTES.CUSTOMER.path}
            component={CustomerLicenses} />

          <Route
            exact
            path={ROUTES.LICENSE_CREATE.path}
            component={FormLicense} />

          <Route
            exact
            path={ROUTES.REGISTRATION_LINK.path}
            component={RegistrationLink} />

          <Route
            exact
            path={ROUTES.CUSTOMERS_SEARCH.path}
            component={SearchCustomers} />

          <Route
            exact
            path={ROUTES.ACCOUNT_PROFILE.path}
            component={Profile} />

          <Route
            exact
            path={ROUTES.PORTAL_ADMIN.path}
            component={PortalAdmin} />

          <Route
            exact
            path={ROUTES.ACCOUNT_BRANCHES.path}
            component={AccountBranches} />

          <Route
            exact
            path={ROUTES.ACCOUNT_AUTH_USERS.path}
            component={AccountAuthUsers} />

          <Route
            exact
            path={ROUTES.ACCOUNT_AUTH_USER_CREATE.path}
            component={FormAuthUser} />
           
          <Route
            exact
            path={ROUTES.BRANCH_CREATE.path}
            component={FormBranch} />

          <Route
            exact
            path={ROUTES.BRANCH_CREATE.path}
            component={FormBranch} />

          <Route
            exact
            path={ROUTES.PROFILE_EDIT.path}
            component={FormUpdateProfile} />

          <Route component={NotFound} />
        </Switch>
        </div>
    );
  }
}
  
  export default View;
  