import React from 'react';
import {InputField, renderForm, getFormParams} from '../utils'
import {GENDER_OPTIONS} from '../../constants'

export default class FormRegister extends React.Component {

    constructor(){  
      super();
  
      this.title = "Register"
      this.endpoint = '/CAIOOOO'
      let state = {};
      state.fields = [
        new InputField("E-mail", "email", false, {disabled: true}),
        new InputField("Language", "language", false, {type: "select", options: []  }),
        new InputField("First Name", "first_name", false,  {size: 0.4}),
        new InputField("Last Name", "last_name", false, {size: 0.4}),
        new InputField("Gender", "gender", true, {type: "select", options: GENDER_OPTIONS, size: 0.2}),
        new InputField("Full Name", "full_name", false, {size: 1}),
        new InputField("Address", "address", true),
        new InputField("City", "city", true),
        new InputField("State", "state", false),
        new InputField("Zipcode", "zipcode", false),
        new InputField("Country", "country_code", false, {type: "select", options: []  }),
        new InputField("Phone Number", "phone_number", false),
      ];    

      this.state = state;
    }

    componentWillMount(){
      getFormParams(this);
    }
  
    onFormSuccess(){
    }
  
    render (){ 
      return(renderForm(this));
    }
  
}
  