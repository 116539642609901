import React from 'react';
import { FaSearch } from 'react-icons/fa';

export default class AttributesSearch extends React.Component{

    constructor(props){
        super(props);
        let totalSize = this.props.attributes.reduce(((acc, cur) => acc + cur.size), 0);

        let state = {};

        this.nodes = this.props.attributes.map((item) => {
            
            state[item.key] = "";
            return (
                <th key={item.key} style={{width: item.size * 100 / totalSize + "%"}}>
                    {item.searchKey ?
                        <div className="attr-form-field">
                            <FaSearch/>
                            <input onChange={this.handleChange.bind(this)} data-attr={item.searchKey} />
                        </div>
                    :
                        null
                    }
                </th>
            );
        });

        this.state = state;
    }

    handleChange(event) {
        let attr = event.target.getAttribute('data-attr');
        this.setState({[attr]: event.target.value});
        this.props.updateQuery(attr, event.target.value);
    }

    render(){
        return (
            <table className="attribute-search">
                <thead>
                    <tr>
                        {this.nodes}
                    </tr>
                </thead>
            </table>
        );
    }

}
