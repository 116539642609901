import i18n from "./i18n/config"
import {userLanguage} from './utils/currentUser';

i18n.changeLanguage(userLanguage());

class Route {
    constructor(name, path, permission){
        this.name = i18n.t(name);
        this.path = path;
        this.permission = permission;
    }
}

export const ROUTES = {

    DASHBOARD: new Route("Home", "/"),
    // Search
    //SUPPORT_AREAS: new Route(".Support Areas", "/support"),
    LICENSES_SEARCH: new Route("Licenses", "/licenses", "view_license"),
    CUSTOMERS_SEARCH: new Route("Customers", "/customers", "view_customer"),
    LIVOX_USERS_SEARCH: new Route("Livox Users", "/livox-users", "view_livoxuser"),
    //DISTRIBUTOR: new Route(".Distributor Totals", "/distributor-totals"),
    // Account
    PORTAL_ADMIN: new Route("Portal Administration", "/admin", "delete_authuser"),
    ACCOUNT_DEVICES: new Route("My Devices", "/account/devices"),
    ACCOUNT_LIVOX_USERS: new Route("My Livox Users", "/account/livox-users"),
    ACCOUNT_LICENSES: new Route("My Licenses", "/account/licenses"),
    //USAGE_STATS: new Route(".Usage Stats", "/"),
    ACCOUNT_BRANCHES: new Route("My Branches", "/account/branches"),
    ACCOUNT_AUTH_USERS: new Route("My Authorized Users", "/account/auth-users"),
    // Top bar
    ACCOUNT_PROFILE: new Route("My Profile", "/profile"),
    // Forms
    CUSTOMER_CREATE: new Route("New Customer", "/customers/create"),
    BRANCH_CREATE: new Route("New Branch", "/account/branches/create"),
    ACCOUNT_AUTH_USER_CREATE: new Route("New Portal User", "/account/auth-users/create"),
    // Subroutes
    ///Licenses
    INSTALL_KEY: new Route("Install Key", "/install-key/:installKey"),
    CUSTOMER: new Route("Customer", "/customers/:id"),
    ////Licenses > Customer > License
    LICENSE_CREATE: new Route("New License", "/licenses/create"),
    CREATE_AUTH_USER: new Route("New Authorized User", "/support/auth-users/create"),
    REGISTRATION_LINK: new Route("New Registation Link", "/install-key/:installKey/link"),
    // Profile
    PROFILE_EDIT: new Route("Edit Profile", "/profile/edit"),
    CHANGE_PASSWORD: new Route("Change Password", "/profile/change-pass"),
    // Register
    REGISTER: new Route("Register", "/register/:token"),
}

export const NAV_BAR = [
    {
        name: "Search",
        permission: "view_license",
        content: [
            //ROUTES.SUPPORT_AREAS,
            ROUTES.LICENSES_SEARCH,
            ROUTES.CUSTOMERS_SEARCH,
            ROUTES.LIVOX_USERS_SEARCH,
            //ROUTES.DISTRIBUTOR,
        ]
    }, {
        name: "Account",
        content: [
            ROUTES.PORTAL_ADMIN,
            ROUTES.ACCOUNT_DEVICES,
            ROUTES.ACCOUNT_LIVOX_USERS,
            ROUTES.ACCOUNT_LICENSES,
            //ROUTES.USAGE_STATS,
            ROUTES.ACCOUNT_BRANCHES,
            ROUTES.ACCOUNT_AUTH_USERS,       
        ]
    }
];