import React from 'react';
import {Attribute, renderSearchTable} from '../utils'
import {ROUTES} from '../../routes'
import { Link } from "react-router-dom";

export default class CustomersSearch extends React.Component {

    constructor(){  
      super();
  
      this.title = "Customers Search"
      this.endpoint = "/portalapi/customers/search"
      this.attributes = [
        new Attribute("Name", "name", 2, true, (key, data) => <Link to={"/customers/"+ data.id}> {key} </Link>),
        new Attribute("Licenses", "licenses_count", 0.5),
        new Attribute("Available", "licenses_available", 0.5),
        new Attribute("Active", "licenses_activated", 0.5),
        new Attribute("Inactive", "licenses_inactivated", 0.5),    
        new Attribute("City", "city", 1, true),
        new Attribute("State", "state", 0.5, true),
        new Attribute("Country", "country_code", 0.5, true),
        new Attribute("Phone", "phone", 1, true),
      ];
  
      this.links = [ROUTES.CUSTOMER_CREATE];
  
    }
  
    render (){ 
      return(renderSearchTable(this));
    }
  
  }
  