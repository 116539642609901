import React, {useState} from 'react';
import { FaCaretDown, FaCaretUp, FaSort } from "react-icons/fa";

const Header = props => {
    
    const [order, setOrdering] = useState("");

    const onOrderChange = (e) => {
        let key = e.target.getAttribute("data-attr-key")
        let ordering;
        if (key === order){
            ordering = "-" + key;
        } else {
            ordering = key;
        }
        setOrdering(ordering);
        props.updateQuery('ordering', ordering);
    }
    
    let totalSize = props.attributes.reduce(((acc, cur) => acc + cur.size), 0)

    const nodes = props.attributes.map((item) => {
        const attrKey = item.searchKey || item.key;
        let icon = <FaSort/>;
        if (order === attrKey){
            icon = <FaCaretUp/>
        } else if (order === "-" + attrKey){
            icon = <FaCaretDown/>
        }

        return (
            <th key={item.key} data-attr-key={attrKey} style={{width: item.size * 100 / totalSize + "%"}} onClick={onOrderChange}>
                {item.name}
                {icon}
            </th>
        );
    });

    return (
        <thead>
            <tr>
                {nodes}
            </tr>
        </thead>
    );
}

const Row = props => {

    const nodes = props.attributes.map((item) => {

        let node;
        if (item.render){
            node = item.render(props.data[item.key], props.data);
        } else {
            node = props.data[item.key]
        }

        return (
            <td key={item.key}>
                {node}
            </td>
        );
    });
    
    return (
            <tr>
                {nodes}
            </tr>
    );
}

const Body = props => {
    
    const attributes = props.attributes;
    const nodes = props.results.map((item) => {
        return (
            <Row key={item.id} data={item} attributes={attributes} />
        );
    });

    return (
        <tbody>
            {nodes}
        </tbody>
    );
}

class Table extends React.Component{
    
    render(){
        return (
            <table id="data-table">
                <Header attributes={this.props.attributes} updateQuery={this.props.updateQuery}/>
                <Body attributes={this.props.attributes} results={this.props.results}/>
            </table>
        );
  }
}

export default Table;
