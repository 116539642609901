import React from 'react';
import { NavLink } from "react-router-dom";
import './NavBar.scss';
import { NAV_BAR } from '../routes';
import logo from '../livox-logo.png';
import { Link } from "react-router-dom";
import UserCard from './UserCard';
import { hasPermission } from '../utils/currentUser';

const ItemsList = props => {

    const nodes = props.routes.map((route) => {

        if (!hasPermission(route.permission)){
            return null;
        }

        return (
            <NavLink key={route.path} to={route.path} activeClassName="link-active">
                <li>               
                    {route.name}
                </li>
            </NavLink>
        );
    });

    return nodes;
}

const AreasList = props => { 

    const nodes = props.sidebar.map((section) => {

        if (!hasPermission(section.permission)){
            return null;
        }
        return (
            <div key={section.name}>
                <h5 className="title">
                    {section.name}
                </h5>
                <ul>
                    <ItemsList routes={section.content}/>
                </ul>
            </div>
        );
    });

    return nodes;
}

class NavBar extends React.Component{
    render(){
        return (
            <aside>
                <Link to="/" className="livox-logo">
                    <img src={logo} alt="logo" />
                </Link>
                <UserCard logOut={this.props.logOut}/>
                <AreasList sidebar={NAV_BAR}/>
            </aside>
        );
    }    
}

  export default NavBar;
  