import React from 'react';
import {Attribute, dateToLocale, renderSearchTable} from '../utils'
import { Link } from "react-router-dom";

export default class LivoxUsersSearch extends React.Component {

    constructor(){  
      super();
  
      this.title = "All Livox users"
      this.endpoint = "/portalapi/livoxuser/search"
      this.attributes = [
        new Attribute("First Name", "first_name", 1, true),
        new Attribute("Last Name", "last_name", 1, true),
        new Attribute("Gender", "gender", 0.5, true),
        new Attribute("Age", "birthdate", 1),
        new Attribute("Evaluations", "num_evaluations", 0.6),
        new Attribute("Last Evalution", "last_evaluation", 1, null, dateToLocale),
        new Attribute("Customer", "customer_name", 1, 'license__customer__name', (key, data) => <Link to={"/customers/"+ data.customer_id}> {key} </Link>),
        new Attribute("License", "license_key", 1.2, 'license__install_key'),
      ];
  
    }
  
     render (){ 
      return(renderSearchTable(this));
    }
  
  }
  