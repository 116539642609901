import React from 'react';
import {Attribute, renderSearchTable} from '../utils'
import {DEFAULT_URL} from "../../constants"
import { FaTrash } from 'react-icons/fa';

export default class AccountDevices extends React.Component {

    constructor(){  
      super();
      this.handleDeleteDevice = this.handleDeleteDevice.bind(this);
      this.title = "My Devices";
      this.endpoint = "/portalapi/mydevices";
      this.attributes = [
        new Attribute("Device Name", "device_name", 2),
        new Attribute("Status", "status", 2),
        new Attribute("Version", "device_version", 2),
        new Attribute("Remove Device", "id", 1, null, (value) => <button onClick={this.handleButton} data-id={value}>  <FaTrash/>  </button>)
      ];
    }
  
    handleDeleteDevice(event) {
      let that = this;
      fetch(DEFAULT_URL + '/portalapi/ajax/removedevice', {
        method: 'POST',
        headers: {
          'Content-Type': "application/json",
          'Authorization': `JWT ${window.localStorage.getItem("token")}`
        },
        body: JSON.stringify({id : event.target.getAttribute('data-id')})
      }).then(() => {
        that.forceUpdate();
      })
    }
  
    render (){ 
      return(renderSearchTable(this));
    }
  
  }
  