import React from 'react';
import {DEFAULT_URL} from "../../constants"

export default class PortalAdmin extends React.Component {

  componentDidMount() {
    document.location.replace(DEFAULT_URL + "/admin")
  }

  render (){
    return(null);
  }
  
}