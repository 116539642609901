import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

export default class Pagination extends React.Component{

    constructor(){
        super();
        this.state = {
            offset: 0,
            limit: 20
        }
    }

    onNextClick() {
        this.setState({offset: this.state.offset + this.state.limit}, () => this.props.updateQuery("offset", this.state.offset))
    }

    onPrevClick() {
        this.setState({offset: this.state.offset - this.state.limit}, () => this.props.updateQuery("offset", this.state.offset))
    }

    render(){
        return (
            <div className="pagination">
                <button onClick={this.onPrevClick.bind(this)} disabled={this.state.offset === 0}>
                    <FaAngleLeft/>
                </button>
                <div className="pagination-label">
                    <span className="page"> 
                        Page ({1 + (this.state.offset / this.state.limit)} of {Math.ceil(this.props.data.count / this.state.limit)})  
                    </span>      
                    <span className="results">  
                        {this.props.data.count} results 
                    </span>
                </div>
                <button onClick={this.onNextClick.bind(this)} disabled={this.state.offset + this.state.limit > this.props.data.count}>
                    <FaAngleRight/>
                </button>

            </div>
        );
    }
}
